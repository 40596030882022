import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Link from "../Global/Link";
import { Helmet } from "react-helmet";

// ====

function toggleMenu(status) {
  if (status === false) {
    // Do something
  } else if (status === true) {
    // Do something else
  }
}

function trimSlash(path) {
  return path.replace(/\/$/, "");
}

const Header = (props) => {
  const { settings } = props;
  const [menuStatus, setMenuStatus] = useState(false);
  const navRef = useRef(null);
  useEffect(() => {
    toggleMenu(menuStatus);
  });

  // Site has the pages in it!
  return (
    <>
      {menuStatus === true && (
        <Helmet>
          <body className="brand-body-menu-open" />
        </Helmet>
      )}
      <header className="fixed-top2 position-sticky d-none" id="brandFixedNav">
        <nav className="position-sticky top-0 start-0 end-0 m-3 m-md-5 brand-background-white sticky-top brand-animated brand-animated-burger">
          {settings.navigation && (
            <>
              <div
                className={`brand-menu ${
                  menuStatus === true ? "brand-menu-open" : ""
                }`}
                id="brandMenu"
                ref={navRef}
                onClick={() => setMenuStatus(false)}
                role="navigation"
              >
                <div className="p-5 text-center brand-text-black brand-link-black d-flex w-100 h-100">
                  <div className="d-flex flex-column w-100 align-content-between">
                    {settings.navigation.map((node) => {
                      return (
                        <Link
                          to={`/${trimSlash(node.link.cached_url)}/`}
                          className="brand-font py-2 fs-1"
                          activeClassName="brand-menu-item-active"
                          partiallyActive={node.name === "" ? false : true}
                          onClick={() => setMenuStatus(false)}
                          key={node._uid}
                          smooth
                          stripHash
                        >
                          {node.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              <button
                className={`p-3 brand-background-white hamburger hamburger--spring ${
                  menuStatus === true ? `is-active` : ``
                }`}
                type="button"
                aria-label="Main Navigation"
                onClick={() => setMenuStatus(!menuStatus)}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;

// ====
