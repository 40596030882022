import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ====

// Checks the path to see if there is a trailing slash. If not, it adds it!
function ensureTrailingSlashExists(path) {
  return path.endsWith("/") ? path : (path += "/");
}
function trimSlash(path) {
  return path.replace(/\/$/, "");
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  icon,
  className,
  button,
  font,
  stretched,
  invert,
  target,
  smooth,
  ...other
}) => {
  let classes = "";
  if (button) {
    classes += "btn ";
    if (button === "large") {
      classes += "btn-lg ";
    }
    if (invert) {
      classes += "btn-outline-light border-2 ";
    } else {
      classes += "btn-light ";
    }
  }
  if (font) {
    classes += "brand-font brand-font-caps ";
  }
  if (stretched) {
    classes += "stretched-link ";
  }
  classes += "brand-link";
  // Any internal link (intended for Gatsby)
  // will start with exactly one slash
  // anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  let linkIcon = "";

  if (icon) {
    if (icon === true) {
      // If icon is true but not explicitly set, then set it to a default icon
      if (internal) {
        linkIcon = <FontAwesomeIcon icon="chevron-right" />;
      } else {
        linkIcon = <FontAwesomeIcon icon="external-link-alt" />;
      }
    } else {
      linkIcon = <FontAwesomeIcon icon={icon} />;
    }
  }

  if (button === "real") {
    return (
      <button
        type="submit"
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={`${classes && classes}${className && ` ${className}`}`}
        {...other}
      >
        {children}
        {linkIcon && <span className="ml-2">{linkIcon}</span>}
      </button>
    );
  }

  // Set window target
  let window = "_self";
  if (!internal) {
    window = "_blank";
  }
  if (target) {
    window = target;
  }
  if (smooth) {
    // Use Gatsby Link for internal links, and <a> for others
    return (
      <AnchorLink
        to={trimSlash(to)}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={`${classes && classes}${className && ` ${className}`}`}
        {...other}
      >
        {children}
        {linkIcon && <span className="ms-2">{linkIcon}</span>}
      </AnchorLink>
    );
  }

  if (internal) {
    // Use Gatsby Link for internal links, and <a> for others
    return (
      <GatsbyLink
        to={ensureTrailingSlashExists(to)}
        target={window}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={`${classes && classes}${className && ` ${className}`}`}
        {...other}
      >
        {children}
        {linkIcon && <span className="ms-2">{linkIcon}</span>}
      </GatsbyLink>
    );
  }
  return (
    <a
      href={to}
      target={window}
      rel="noopener"
      className={`${classes && classes}${className && ` ${className}`}`}
      {...other}
    >
      {children}
      {linkIcon && <span className="ms-2">{linkIcon}</span>}
    </a>
  );
};

export default Link;

// ====

Link.propTypes = {
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  button: PropTypes.bool,
  font: PropTypes.string,
  stretched: PropTypes.bool,
  invert: PropTypes.bool,
  target: PropTypes.string,
};

Link.defaultProps = {
  to: null,
  activeClassName: null,
  partiallyActive: null,
  icon: null,
  className: "",
  button: false,
  font: null,
  stretched: false,
  invert: false,
  target: null,
};
