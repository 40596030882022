/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import * as sanitizeHtml from "sanitize-html";
import ReactMarkdown from "react-markdown";
import SbResolve from "./SbResolve";

// ====

const SmartText = (props) => {
  const { children, content, mode, limit, className } = props;

  const dirty = SbResolve(children || content);
  const cleanNormal = sanitizeHtml(dirty, {
    allowedTags: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "b",
      "i",
      "em",
      "strong",
      "strikethrough",
      "s",
      "a",
      "p",
      "span",
      "ul",
      "ol",
      "li",
      "u",
      "hr",
      "img",
      "br",
    ],
    allowedAttributes: {
      a: ["href", "target", "alt"],
      span: ["class"],
      img: ["src", "alt", "width", "height", "style", "class"],
    },
    allowedIframeHostnames: ["www.youtube.com"],
  });

  const cleanSimple = sanitizeHtml(dirty, {
    allowedTags: ["b", "i", "em", "strong", "a", "ul", "li", "p"],
    allowedAttributes: {
      a: ["href"],
    },
  });

  const cleanStrict = sanitizeHtml(dirty, {
    allowedTags: [],
    allowedAttributes: {},
  });

  if (mode === "markdown") {
    return (
      <>
        <ReactMarkdown
          escapeHtml
          source={children}
          disallowedTypes={["html", "virtualHtml", "parsedHtml"]}
          className={className && className}
        />
      </>
    );
  }

  if (mode === "text") {
    if (limit !== null) {
      return cleanStrict.length > limit
        ? `${cleanStrict.substring(0, limit)}...`
        : `${cleanStrict}`;
    }

    return cleanStrict;
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: mode === "simple" ? cleanSimple : cleanNormal,
      }}
      className={className && className}
    />
  );
};

export default SmartText;

// ====

SmartText.propTypes = {
  limit: PropTypes.number,
  mode: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
};

SmartText.defaultProps = {
  limit: null,
  mode: "normal",
  className: null,
};
