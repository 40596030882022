import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SocialLinks from "../Global/SocialLinks";

const Footer = (props) => {
  const { settings } = props;

  return (
    <footer className="w-100 py-5 brand-background-black brand-text-white brand-link-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <SocialLinks
              className="brand-text-white fs-1"
              itemClassName="mx-3"
              links={[
                ["facebook", settings.facebook],
                ["twitter", settings.twitter],
                ["instagram", settings.instagram],
                ["linkedin", settings.linkedin],
              ]}
            />
          </div>
          <div className="col-12 text-center brand-footer-terms small">
            <SmartText content={settings.footer} mode="simple" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mode: PropTypes.string,
};

Footer.defaultProps = {
  settings: null,
  mode: "normal",
};
